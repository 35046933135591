#home {
    position: relative;
    background: linear-gradient(180deg, #112d4e, #3f72af, );
    background-size: 800% 800%;
    -webkit-animation: Gradient 60s ease infinite;
    -moz-animation: Gradient 60s ease infinite;
    animation: Gradient 60s ease infinite;


    @-webkit-keyframes Gradient {
        0%{background-position:51% 0%}
        50%{background-position:50% 100%}
        100%{background-position:51% 0%}
    }
    @-moz-keyframes Gradient {
        0%{background-position:51% 0%}
        50%{background-position:50% 100%}
        100%{background-position:51% 0%}
    }
    @keyframes Gradient {
        0%{background-position:51% 0%}
        50%{background-position:50% 100%}
        100%{background-position:51% 0%}
    }

    .app__wrapper {
        padding: 0;
    }
}

.app__header{
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: row;
    padding: 6rem 2rem 0;

    @media screen and (min-width: 2000px) {
        padding-top: 8rem;
    }
     /*my add*/
    @media screen and (min-width: 1285px) {
        max-width: 1285px;
    }

    @media screen and (max-width: 700px) {
        padding: 1rem;
    }

    @media screen and (max-width: 450px) {
        padding: 6rem 1rem 2rem;
    }
}

.app__header-info {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 90%;

    background-color: var(--primary-color);
    box-shadow: 0 0 20px rgba(0,0,0,0.1);
    border-radius: 15px;
    
    @media screen and (max-width: 2000px) {
        width: 100%;
        margin-right: 0rem;
    }

    @media screen and (max-width: 880px){
        flex-direction: column-reverse;
        max-height: 100%;
    }

    @media screen and (max-width: 700px) {
        flex-direction: column-reverse;
        max-height: 100%;
    }
}

.app__header-description {
    width: 100%;
    display: flex;
    flex: 2;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 1rem 2rem;
    max-width: 50%;
    
    .head-text {
        color: var(--secondary-color);
    }

    .p-text{
        font-size: 1.5rem;
    }

    span {
        font-size: 2.5rem;
    
        @media screen and (min-width: 2000px) { 
            font-size: 5rem;
        }
    }

    @media screen and (max-width: 1200px) {
        justify-content: flex-start;
        align-items: flex-start;
    }
    @media screen and (max-width: 700px) {
        justify-content: flex-start;
        align-items: flex-start;
        padding: 1rem;
        max-width: 100%;
        flex: 1;

        .p-text {
            text-align: center;
        }
    }
}

.app__header-image {
    justify-content: center;
    align-items: center;
    flex: 0.5;
    max-height: 100%;
    object-fit: cover;

    img {
        max-width: 50%;
        max-height: 50%;
        border-radius: 65%;
        @media screen and (max-width: 1200px) {
            max-width: 80%;
            max-height: 80%;
        }
        @media screen and (max-width: 880px) {
            max-width: 60%;
            max-height: 60%;
        }

        @media screen and (max-width: 700px) {
            max-width: 60%;
        }
    }

    @media screen and (max-width: 1200px) {
        justify-content: center;
        align-items: center;
    }
    @media screen and (max-width: 880px) {
        max-height: 100%;
    }
    @media screen and (max-width: 700px) {
        width: 100%;
        height: 100%;
    }
}